<template>
  <router-view />
</template>

<script>

export default {
  setup() {
    return {
    }
  },
  mounted() {
    setTimeout(function () {
      let elem = document.getElementById('krtoolbar')
      if (elem) {
        elem.style.visibility = 'hidden'
        elem.style.display = 'none'
      }
    }, 2000)
  }
}
</script>

<style>
@import "./style.css";

body {
  background-color: #FAFAFA;
}

#app {
  font-family: 'NEXA', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
