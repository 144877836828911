<template>
    <v-dialog v-model="connectionModalisOpen" width="auto">
        <v-card>
            <v-col cols="auto">
                <RedirectionCompte :connected="false" :login="login" :verifClientIsOver="verifClientIsOver"
                    :isUserConnected="isUserConnected" :isValidUser="isValidUser" :userInformation="userInformation">
                </RedirectionCompte>
            </v-col>
        </v-card>
    </v-dialog>
    <div style="width:100%;position: sticky;top:0px;left:0px;z-index: 999;" :elevation="2" height="50">
        <v-col class="pa-0">
            <v-card
                style="background-color:#E0E0E0;height:100%; width: 100%;border-radius: 5px;border-radius: 0px;opacity: 1;"
                class="">
                <v-row justify="center" align="center">
                    <v-col align="left" class="ml-3 mt-2" cols="auto">
                        <img :src="'./medias/logo.png'" :height="isMobile?30:66"  style="cursor:pointer"
                            @click="goTo('/')"><img>
                    </v-col>
                    <v-col  align="center" cols="" style="" class=""
                        v-if="verifClientIsOver == true && (headerTitle && headerTitle.length > 0 || headerSecondeTitle && headerSecondeTitle.length > 0)">
                        <div :style="{'font-size': isMobile?'12px':'20px'}" v-if="headerTitle && headerTitle.length > 0" class="titleText">
                            {{ headerTitle }}
                        </div>
                        <div :style="{'font-size': isMobile?'10px':'15px'}" v-if="!isMobile && headerSecondeTitle && headerSecondeTitle.length > 0" style="color:#757575" >
                            {{ headerSecondeTitle }}
                        </div>
                    </v-col>
                    <v-spacer v-else></v-spacer>
                    <!-- <v-col v-else cols="">
                    </v-col> -->
                    <v-col class=" mr-3" align="right" cols="auto">
                        <!-- {{ userInformation }} -->
                        <div v-if="verifClientIsOver">
                            <div v-if="userInformation">
                                <v-menu open-on-hover open-delay="0" close-delay="0">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" style="background-color: var(--C1); color: white;"
                                            @click="goTo('/client')">
                                            <v-icon size="30" class="mr-2">mdi-account</v-icon>
                                            <div style="font-size: 15px;" v-if="!isMobile">
                                                {{ userInformation.lastname }}
                                                {{ userInformation.firstname }}
                                            </div>
                                        </v-btn>
                                        <!-- <v-btn color="primary" dark v-bind="props">
                                                Dropdown
                                            </v-btn> -->
                                    </template>

                                    <v-list>
                                        <v-card v-for="(item, index) in clientDropdown" :key="index" variant="flat"
                                            @click="goTo(item.url)">
                                            <!-- <v-btn  style="width: 100%;" variant="flat" > -->
                                            <!-- <v-row justify="left" align="left"> -->
                                            <!-- <v-col cols="" align="left">
                                                            <v-icon>
                                                                mdi-home
                                                            </v-icon>
                                                        </v-col>
                                                        <v-col cols=""> -->
                                            <v-col>
                                                <v-row>
                                                    <v-col cols="2">
                                                        <v-icon>
                                                            {{ item.icon }}
                                                        </v-icon>
                                                    </v-col>
                                                    <v-col cols="10" align="left">
                                                        {{ item.label }}
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <!-- </v-col>
                                                    </v-row> -->
                                            <!-- </v-btn> -->
                                        </v-card>
                                    </v-list>
                                </v-menu>
                                <v-btn class="ml-1" color="red" icon="mdi-logout" variant="text" @click="logOut()"></v-btn>
                            </div>
                            <div v-else>
                                <v-btn style="background-color: var(--C1); color: white;"
                                    @click="connectionModalisOpen = true">Se connecter</v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </div>
</template>
<script>

// import clientHook from "@/hooks/clientHook"
import RedirectionCompte from '@/components/home/redirection_compte.vue'

export default {
    props: {
        headerTitle: {},
        headerSecondeTitle: {},
        userInformation: {},
        isValidUser: {},
        isUserConnected: { type: Function },
        login: { type: Function },
        verifClientIsOver: {},
    },
    data() {
        return {
            clientDropdown: [
                { label: 'Gestion de compte', icon: 'mdi-account', url: 'client' },
                { label: 'Planning', icon: 'mdi-calendar', url: 'planning' },
                { label: 'Documents', icon: 'mdi-file-account-outline', url: 'documents' },
            ],
            connectionModalisOpen: false
        }
    },
    setup() {
        // const { userInformation, isValidUser, isUserConnected } = clientHook()
        // // const { login, userInformation, isValidUser, verifClientIsOver } = clientHook()
        // return { userInformation, isValidUser, isUserConnected }
    },
    components: {
        RedirectionCompte
    },
    computed: {
        isMobile() {
            return this.$vuetify.display.smAndDown
        }
    },
    watch: {
        isValidUser(val) {
            if (val) {
                this.connectionModalisOpen = false
            }
        }
    },
    methods: {
        goTo(url) {
            if (this.$route.fullPath == url) {
                location.reload()
            } else
                this.$router.push({ path: url, replace: true })
        },
        logOut() {
            localStorage.clear()
            this.isUserConnected()
        }
    }
}
</script>