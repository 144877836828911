<template>
  <general-header  :login="login" :verifClientIsOver="verifClientIsOver" :headerTitle="headerTitle"
    :headerSecondeTitle="headerSecondeTitle" :isValidUser="isValidUser" :isUserConnected="isUserConnected"
    :userInformation="userInformation"></general-header>
  <div class="main-vue">
    <page-planning v-if="verifClientIsOver && isValidUser"></page-planning>
    <v-col v-else style="margin-top:30vh">
      <basic-loading></basic-loading>
    </v-col>
  </div>
</template>
  
<script>

import GeneralHeader from '@/components/header/header-general.vue'
import PagePlanning from '@/components/planning/page_planning.vue'
import clientHook from "@/hooks/clientHook"
import BasicLoading from '@/components/basic-loading.vue'

export default {
  data() {
    return {
      headerTitle: 'Planning',
      headerSecondeTitle: 'Gestion des rendez-vous'
    }
  },
  setup() {
    const { login, userInformation, isValidUser, verifClientIsOver, isUserConnected } = clientHook()
    return { login, userInformation, isValidUser, verifClientIsOver, isUserConnected }
  },
  watch: {
    isValidUser(val) {
      if (val == false) {
        this.$router.push('/')
      }
    }
  },
  components: {
    GeneralHeader,
    PagePlanning,
    BasicLoading
  }
}
</script>