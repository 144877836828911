<template>
  <input @change="setFilesFrominput" style="display:none" type="file" id="fileLoader">
  <v-dialog v-model="clickedElemData.isMandatoryElemsModalOpen" width="90%" max-width="800">
    <v-card>
      <v-card-title>
        {{ clickedElemData.data.label }}
      </v-card-title>
      <v-col>
        <div v-if="clickedElemData.data.type == 'text'">
          <div v-if="clickedElemData.data.isValide">
            <h3 class="mb-3" style="color:var(--C3)">
              Il n'est pas possible de modifier un iban une fois validé, en cas de changement bancaire, merci de nous
              envoyer par mail votre nouveau RIB
            </h3>
            <v-text-field readonly variant="outlined" v-model="clickedElemData.data.tmpValue" hide-details></v-text-field>
          </div>
          <div v-else>
            <v-text-field variant="outlined" v-model="clickedElemData.data.tmpValue" hide-details></v-text-field>
          </div>
        </div>
      </v-col>
      <v-col align="center" class="pt-0">
        <v-btn v-if="!clickedElemData.data.isValide" style="background-color: var(--C1);color: white;"
          @click="editIban(clickedElemData)"> {{
            'Valider' }}
        </v-btn>
        <v-btn v-else style="background-color: var(--C1);color: white;"
          @click="clickedElemData.isMandatoryElemsModalOpen = false"> {{
            'Fermer' }}
        </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
  <v-col>
    <v-col style="background-color: #ECEFF1;">
      <v-col align="left" class="mb-3">
        <h3 style="color: var(--C3);font-weight: bold;">
          Insérer les documents adminitratifs nécessaires pour la location
        </h3>
      </v-col>
      <v-col v-for="(elem, i) in filesToLoad" :key="i" class="pa-1" align="left">
        <div v-if="elem.type == 'text'" @click="setFiles(elem)">
          <!-- {{ elem }} -->
          <v-text-field v-model="elem.value" :label="elem.label" variant="solo" style="background-color: white;"
            hide-details readonly :clearable="false" chip>
            <template v-slot:default>
              <div v-if="!isMobile" style="position: absolute; right:10px;top:15px">
                <!-- <v-icon v-if="elem.isValide" size="30" color="green"> mdi-checkbox-marked-circle</v-icon> -->
                <v-chip v-if="elem.isValide" style="border-radius: 5px;color: var(--C1);" variant="tonal" width="auto"> {{
                  elem.label }}
                  enregistré</v-chip>
                <v-chip v-else style=" border-radius: 5px;" color="red" variant="tonal" width="auto"> {{ elem.label }}
                  incorrect * </v-chip>
              </div>
            </template>
          </v-text-field>
          <div v-if="isMobile" class="mt-2">
         <v-chip v-if="elem.isValide" style="border-radius: 5px;color: var(--C1);" variant="tonal" width="auto"> {{
              elem.label }}
              enregistré</v-chip>
            <v-chip v-else style=" border-radius: 5px;" color="red" variant="tonal" width="auto"> {{ elem.label }}
              incorrect * </v-chip>
          </div>
        </div>
        <div v-if="elem.type == 'file'">
          <v-row v-if="elem.fileExiste == true" align="center">
            <v-col align="left" cols="" @click="uploadFile(elem)">
              <v-card height="100%" class="pa-3" va style="cursor:pointer">
                <v-col>
                  <v-row>
                    <div v-if="elem.label.includes(';')">
                      <div v-for="line in elem.label.split(';')" :key="line">
                        {{ line }}
                      </div>
                    </div>
                    <div v-else>
                      {{ elem.label }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-chip style="border-radius: 5px;color: var(--C1);" variant="tonal" width="auto">Fichier
                      enregistré</v-chip>
                  </v-row>
                </v-col>
              </v-card>
            </v-col>
            <v-col align="right" cols="auto" class="pl-0">
              <v-btn @click="downloadFile(elem)" :icon="'mdi-download'">
              </v-btn>
            </v-col>
          </v-row>
          <v-card v-else class="pa-3" @click="uploadFile(elem)">
            <v-col>
              <v-row>
                <div v-if="elem.label.includes(';')">
                  <div v-for="line in elem.label.split(';')" :key="line">
                    {{ line }}
                  </div>
                </div>
                <div v-else>
                  {{ elem.label }}
                </div>
                <v-spacer></v-spacer>
                <v-chip style=" border-radius: 5px;" :color="elem.mandatory == 'Oui' ? 'red' : 'orange'" variant="tonal"
                  width="auto">Fichier
                  manquant {{ elem.mandatory == 'Oui' ? '*' : ' - Optionnel' }} </v-chip>
              </v-row>
            </v-col>
          </v-card>
        </div>
      </v-col>
    </v-col>
  </v-col>
</template>
    
<script>

import engine from "@/core/DataAPI"

export default {
  data() {
    return {
      selectedFormValue: undefined,
      fileLoaded: 'Fichier enregistré',
      clickedElemData: { isMandatoryElemsModalOpen: false, data: undefined, value: undefined },
      userInfoCode: undefined,
      // filesInfo: { 'filename': undefined, 'key': undefined, 'label': undefined, 'type': 'file', icon: 'mdi-file-upload', 'fileExiste': false, value: undefined, mandatory: 'Oui' },
      // filesToLoad: [
      //   { 'key': 'IBAN', 'label': 'Iban', 'type': 'text', icon: 'mdi-menu-right', isValide: false, errorMessage: 'Iban non valide', value: undefined },
      // ],
    }
  },
  props: {
    resetFilesToLoad: { type: Function },
    addFilesToLoad: { type: Function },
    validAllMandatoryFiles: { type: Function },
    isValidUser: {},
    verifAllFiel: {},
    filesToLoadListe: {},
    selectedPaymentType: {},
    selectedPaymentAmount: {},
    filesToLoad: {},
    filesInfo: {},
  },
  watch: {
    verifAllFiel() {
      this.getAllMandatoryElems()
    },
    isValidUser() {
      this.getAllMandatoryElems()
    },
    selectedPaymentAmount() {
      this.getAllMandatoryElems()
    },
    filesToLoad(val) {
      console.log("filesToLoad", val);
    }
  },
  mounted() {
    if (this.filesToLoadListe == undefined)
      this.getAllMandatoryElems()
    else {
      this.resetFilesToLoad()
      // console.log("resetFilesToLoad");
      // console.log("ELSE", this.filesToLoadListe);
      for (let i = 0; i < this.filesToLoadListe.length; i++) {
        let file = this.filesToLoadListe[i]
        if (this.filesToLoadListe.find((val) => val['PJ_GM::id_pj'] == file['PJ_GM::id_pj']) != -1) {
          if (file['PJ_GM::flag_sfamille'] == 'Oui') {
            let tmpData = JSON.parse(JSON.stringify(this.filesInfo))
            tmpData.key = file['PJ_GM::nom']
            tmpData.label = file['PJ_GM::libelle']
            tmpData.mandatory = file['PJ::flag_obligatoire']
            this.addFilesToLoad(tmpData)
            // this.filesToLoad.push(tmpData)
          }
          else if (file['PJ_GM::flag_sfamille'] == 'non' && file['PJ_GM::tarif_nom'] == this.selectedPaymentType.nom) {
            let tmpData = JSON.parse(JSON.stringify(this.filesInfo))
            tmpData.key = file['PJ_GM::nom']
            tmpData.label = file['PJ_GM::libelle']
            tmpData.mandatory = file['PJ::flag_obligatoire']
            // this.filesToLoad.push(tmpData)
            this.addFilesToLoad(tmpData)
          }
        }
      }
      this.getAllMandatoryElems()
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown
    },
  },
  methods: {
    editIban(info) {
      let that = this
      engine.Iban_verification(info.data.tmpValue, function (data) {
        if (data.code == 0) {
          engine.Account_Edit(that.userInfoCode, {
            iban: info.data.tmpValue,
            bic: data.data,
          }, function () {
            that.getAllMandatoryElems()
            // info.data.value = info.data.tmpValue
            // info.data.isValide = true
          })
        } else {
          engine.Account_Edit(that.userInfoCode, {
            iban: info.data.tmpValue,
            bic: "",
          }, function () {
            that.getAllMandatoryElems()
            // info.data.tmpValue = info.data.value
            // info.data.isValide = false
          })
        }
      })
      that.clickedElemData.isMandatoryElemsModalOpen = false
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        return reader.result
      };
      reader.onerror = function () {
        return ""
      };
    },
    setFilesFrominput(event) {
      let that = this
      let file = event.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let fileData = that.filesToLoad.find((val) => val.key == that.selectedFormValue.key)
        let filename = that.selectedFormValue.key + '.' + file.name.split('.')[1]

        fileData.value = reader.result
        fileData.filename = filename
        fileData.fileExiste = true
        that.verifFiles()
      };
    },
    downloadFile: function (elem) {
      let download_link = document.createElement("a");
      download_link.href = elem.value;
      download_link.download = elem.filename;
      download_link.target = "_blank";
      document.body.appendChild(download_link);
      download_link.click();
      document.body.removeChild(download_link);
    },
    uploadFile(elem) {
      this.selectedFormValue = elem
      var x = document.getElementById("fileLoader");
      x.click()
    },
    getAllMandatoryElems() {
      let that = this
      engine.get_user_info(function (data) {
        if (data.code == 0) {
          let info = data.data[0]
          if (info.iban && info.iban.length != 0) {
            that.filesToLoad.find((val) => val.key == 'IBAN').value = info.iban
            that.filesToLoad.find((val) => val.key == 'IBAN').tmpValue = info.iban
            engine.Iban_verification(info.iban, function (data) {
              console.log("Iban_verification", data);
              if (data.code == 0) {
                that.filesToLoad.find((val) => val.key == 'IBAN').isValide = true
              } else
                that.filesToLoad.find((val) => val.key == 'IBAN').isValide = false
              that.verifFiles()
            })
          }
          that.userInfoCode = info.code
          // for (let i = 0; i < that.filesToLoad.length; i++) {
          //   let file = that.filesToLoad[i]
          //   if (file.type == 'file') {
          //     let filename = file.key
          //     engine.File_Exist(filename, info.code, function (data) {
          //       if (data.data == true) {
          //         that.filesToLoad.find((val) => val.key == file.key).fileExiste = true
          //       }
          //       that.verifFiles()
          //     })
          //   }
          // }
        }
      })
    },
    verifFiles() {
      let errorFile = this.filesToLoad.find((val) => (
        (val.isValide == false && val.mandatory == 'Oui') || (val.fileExiste == false && val.mandatory == 'Oui')
      ))
      if (this.validAllMandatoryFiles != undefined) {
        if (errorFile) {
          this.validAllMandatoryFiles(false)
        }
        else {
          this.validAllMandatoryFiles(true)
        }
      }
    },
    setFiles(file) {
      if (file.type == "text") {
        this.clickedElemData.data = file
        this.clickedElemData.value = file.value
        this.clickedElemData.isMandatoryElemsModalOpen = true
      }
    }
  }
}
</script>

<style>
.inputWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  /*This makes the button huge so that it can be clicked on*/
  font-size: 50px;
}

.hidden {
  /*Opacity settings for all browsers*/
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0)
}


/*Dynamic styles*/
.inputWrapper:hover {
  background-color: #FDD;
}

.inputWrapper.clicked {
  background-color: #A66;
}
</style>