<template>
  <client-header  :login="login" :verifClientIsOver="verifClientIsOver" :headerTitle="headerTitle" :headerSecondeTitle="headerSecondeTitle"
    :userInformation="userInformation" :isValidUser="isValidUser" :isUserConnected="isUserConnected">
  </client-header>
  <v-row class="main-vue" justify="center" align="center" style="margin-top:25vh">
    <v-col cols="10" md="6">
      <v-card color="#EEEEEE" variant="flat" class="pa-2" style="border:solid 1px lightgrey">
        <div v-if="type == 0">
          <v-icon class="mb-3" size="90">mdi-emoticon-happy-outline</v-icon>
          <h2>
            Votre réservation a bien été prise en compte, un récapitulatif vient de vous être envoyé.
          </h2>
          <a href="/">Accueil</a>
        </div>
        <div v-if="type == 1">
          <v-icon class="mb-3" size="90">mdi-emoticon-sad-outline</v-icon>
          <h2>
            Une erreur c'est produit lors de votre réservation nous vous invitons à recommencer.
          </h2>
          <a href="/">Accueil</a> /
          <a href="/reservation">Reservation</a>
        </div>
        <div v-if="type == 2">
          <v-icon class="mb-3" size="90">mdi-emoticon-happy-outline</v-icon>
          <h2>
            Votre renouvellement a bien été prise en compte, un récapitulatif vient de vous être envoyé.
          </h2>
          <a href="/">Accueil</a>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
  
<script>

import ClientHeader from '@/components/header/header-general.vue'
import clientHook from "@/hooks/clientHook"

export default {
  setup() {
    const { login, userInformation, isValidUser, verifClientIsOver, isUserConnected } = clientHook()
    return { login, userInformation, isValidUser, verifClientIsOver, isUserConnected }
  },
  mounted() {
    if (this.$route.query.type == 'succes') {
      this.type = 0
      // this.$router.replace({ 'query': null });
    }
    else if (this.$route.query.type == 'error') {
      this.type = 1
      // this.$router.replace({ 'query': null });
    }
    else if (this.$route.query.type == 'renouvellement') {
      this.type = 2
      // this.$router.replace({ 'query': null });
    }
    // else {
    //   this.$router.push('/')
    // }
  },
  data() {
    return {
      type: undefined,
      headerTitle: "",
      headerSecondeTitle: ""
    }
  },
  watch: {
    isValidUser(val) {
      if (val == false) {
        this.$router.push('/')
      }
    }
  },
  components: {
    ClientHeader,
  }
}
</script>