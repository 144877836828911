<template>
  <general-header  :login="login" :verifClientIsOver="false" :headerTitle="headerTitle"
    :headerSecondeTitle="headerSecondeTitle"></general-header>
  <div style="margin-top:30vh">
    <div v-if="isLoading == false">
      <div v-if="viewdiv == 'valid'" class="divlogin">
        <h2 style="margin:0px;margin-top:5px;margin-bottom:25px;">Validation de votre compte</h2>
        <h3 style="margin:0px;cursor:pointer;">Votre compte a été validé avec succès<br /><br /></h3>
      </div>
      <div v-else>
        <h2 style="margin:0px;margin-top:5px;margin-bottom:25px;">Erreur dans la validation de votre compte</h2>
        <h3 style="margin:0px;cursor:pointer;">il est possible que votre lien de validaton est déja été
          utilisé.<br /><br />
        </h3>
      </div>
    </div>
    <div v-else>
      <basic-loading></basic-loading>
    </div>
    <v-col cols="auto">
      <a href="/">Retour sur la page d'Accueil</a>
    </v-col>
  </div>
</template>

<script>
import engine from "../core/DataAPI";
import GeneralHeader from '@/components/header/header-general.vue'
import clientHook from "@/hooks/clientHook"
import BasicLoading from '@/components/basic-loading.vue'

export default {
  components: {
    GeneralHeader,
    BasicLoading
  },
  setup() {
    const { login, userInformation, isValidUser, verifClientIsOver, isUserConnected } = clientHook()
    return { login, userInformation, isValidUser, verifClientIsOver, isUserConnected }
  },
  data() {
    return {
      isLoading: true,
      viewdiv: '',
    }
  },
  created: function () {
    var tmain = this;
    if (JSON.stringify(tmain.$route.query) != '{}') {
      switch (tmain.$route.query.state) {
        case 'valid':
          engine.Account_Create_Valid(tmain.$route.query.apikey, function (pkgv) {
            if (pkgv.code == 0) {
              tmain.viewdiv = 'valid';
            }
            tmain.$router.push('/account/create');
            tmain.isLoading = false
          });
          break;
      }
    } else {
      tmain.$router.push('/')
    }
  },
  methods: {
  }
}
</script>
<style></style>
