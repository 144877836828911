<template>
  <div style="background-color: var(--C1);position:static;bottom: 0px;width: 100vw;">
    <v-col>
      <v-row align="center" style="color:var(--C3)">
        <v-col class="py-1">
          <div>
            Mail : reservation@ogalo-saumurvaldeloire.fr <br>
          Tél : 02 41 51 12 62
          </div>
        </v-col>
        <v-col class="py-1">
         <a style="color:var(--C3)" target="_blank" href="/CGLU.pdf">Condition générales de confidentialité</a> 
        </v-col>
        <v-col class="py-1">
          Made by Accen informatique
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {

}
</script>

<style></style>