<template>
  <v-col>
    <v-col style="background-color: var(--C6);">
      <v-col align="left" class="mb-3">
        <h3 style="color: var(--C3);font-weight: bold;">
          Sélectionner le type de règlement
        </h3>
      </v-col>
      <v-card>
        <v-item-group mandatory v-model="selectedPaymentAmount">
          <v-col>
            <div v-for="(price, i) in selectedPaymentType.prices" :key="i" style="width:100%" class="py-1">
              <v-item v-slot="{ isSelected, toggle }">
                <v-card 
                style="border: solid 1px lightgrey;"
                  :style="isSelected ? 'background-color:var(--C1);color:white' : ''" class="pa-2" min-height="10"
                  @click="toggle"
                  variant="text"
                  >
                  <!-- {{  price }} -->
                  <v-row>
                    <v-col cols="5" md="3" align="left" style="text-transform: uppercase;font-weight:bold;">
                      {{ price.label }}
                    </v-col>
                    <v-col cols="5" md="8" align="left">
                      {{ price.description }}
                    </v-col>
                    <v-col style="font-weight: bold" cols="2" md="1" align="right">
                      {{ price.amount.toFixed(2) }}€
                    </v-col>
                  </v-row>
                </v-card>
              </v-item>
            </div>
          </v-col>
        </v-item-group>
      </v-card>
    </v-col>
  </v-col>
</template>
  
<script>
export default {
  data() {
    return {
      selectedPaymentAmount: undefined
    }
  },
  watch: {
    selectedPaymentType(){
      this.selectedPaymentAmount = undefined
      this.setSelectedPaymentAmount(undefined)
    },
    selectedPaymentAmount(val) {
      this.setSelectedPaymentAmount(val)
    }
  },
  props: {
    selectedPaymentType:{},
    setSelectedPaymentAmount: {
      type: Function
    }
  },
  setup() {

  },
}
</script>