<template>
    <v-card v-if="isLoading == false">
        <div style="position: relative;">
            <object id="pdfViewer"  :data="fileAndSignature" type="application/pdf"
                width="100%" style="height:87vh">
                <!-- <embed  title="Title of my video"  :src="fileAndSignature" width="100%" style="height:93.3vh" type="application/pdf"> -->
            </object>
            <v-col class="pt-0" style="background-color: #E0E0E0;">
                <v-row justify="center" align="center" class="pt-2">
                    <v-col align="center">
                        <v-btn @click="closeModal" color="#E57373" style="color: white;width: 100%;">Fermer</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </div>
    </v-card>
    <div v-else style="margin-top:40vh" align=center>
        <v-progress-circular :size="160" :width="7" color="#7986CB" indeterminate>
            Chargement</v-progress-circular>
    </div>
</template>
<script>

import engine from '@/core/DataAPI'

const Buffer = require('buffer').Buffer

export default {
    data() {
        return {
            isLoading: true,
            fileUrl: undefined,
            fileSize: { height: 841, width: 594 },
            signatureBase64: undefined,
            fileAndSignature: undefined,
            isSignatureOpen: false
        }
    },
    mounted() {
        let that = this
        engine.get_file_from_path(this.fileIsSelectedData.fileData.path_display,  function (data) {
            if (data.code == 0) {
                let b64 = "data:application/pdf;base64," + new Buffer.from(data.data.fileBinary).toString('base64');
                that.fileUrl = b64
                that.fileAndSignature = b64
                that.isLoading = false
            }
        })
    },
    methods: {
        closeModal(){
            this.$emit("closeModal")
        }
    },
    computed: {
        isEditable() {
            return this.fileIsSelectedData.isSigned == 'NOK'
        },

    },
    props: {
        fileIsSelectedData: {}
    },
    components: {
    }
}
</script>

<style></style>