<template>
  <v-col>
    <v-col style="background-color: var(--C6);">
      <v-col align="left" class="mb-3">
        <h3 style="color: var(--C3);font-weight: bold;">
          Sélectionnez les accessoires que vous souhaitez ajouter
        </h3>
      </v-col>
      <v-col>
        <v-item-group v-model="selectedAccessoire" return-object>
          <v-row align="center" justify="center">
            <v-col v-for="(acc, i) in selectedMaterial.accessories" :key="i" cols="12" md="3" lg="4">
              <v-item v-slot="{ isSelected, toggle }" :disabled="acc['ComplementLocation::Qte'] <= 0">
                <div :style="isSelected ? 'background-color: var(--C1);border-radius:5px;' : ''" class="pa-1">
                  <v-card class="pa-2 " @click="toggle" align="center" 
                    :style="{ 'background-color': acc['ComplementLocation::Qte'] <= 0 ? '#FFEBEE' : 'white' }">
                    <div>
                      <img :src="'./sub_family/' + acc['ComplementLocation::imageLabel'] + '.jpg'" style="width: 100%; max-width: 200px;"><img>
                      <div>
                        <h3>
                          {{ acc['ComplementLocation::Désignation'] }}
                        </h3>
                      </div>
                      <div v-if="acc['ComplementLocation::Qte'] <= 0">
                        <h3 style="color:rgb(239, 83, 80)">
                          Actuellement indisponible
                        </h3>
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      selectedAccessoire: []
    }
  },
  props: {
    selectedMaterial: {},
    updateSelectedAccessoireByIndex: {
      type: Function
    }
  },
  watch: {
    selectedAccessoire(val) {
      if (val == undefined) {
        this.updateSelectedAccessoireByIndex(val)
      } else
        this.updateSelectedAccessoireByIndex([val])
    }
  }
}
</script>

<style></style>