<template>
    <v-col>
        <v-col style="background-color: var(--C6)">
            <v-col align="left" class="mb-3">
                <h3 style="color: var(--C3);font-weight: bold;">
                    Liste d'attente
                </h3>
            </v-col>
            <v-col v-for="elem in Object.values(clientInformation)" :key="elem.label">
                <v-text-field :label="elem.label" variant="solo" hide-details class="" :type="elem.type"
                    v-model="elem.value">
                </v-text-field>
            </v-col>
            <v-col align="left">
                <v-btn
                    :disabled="(Object.values(clientInformation).find((val) => val.value.length == 0) == undefined) == false"
                    style="background-color: var(--C1);color:white;text-transform: none;" @click="sendEmail">
                    Être contacté quand un(e)
                    <div style="font-weight: bold;" class="mx-1">
                        {{ selectedMaterial.nom }}
                    </div>
                    est disponible
                </v-btn>
                <div class="mt-2"
                    v-if="(Object.values(clientInformation).find((val) => val.value.length == 0) == undefined) == false"
                    style="color:#EF5350">
                    Tous les champs du formulaire doivent être remplis.
                </div>
            </v-col>
        </v-col>
    </v-col>
</template>

<script>

import engine from '@/core/DataAPI'

export default {
    props: {
        showSnackBarFunct: { type: Function },
        userInformation: {},
        selectedMaterial: {},
        setMaterial: { type: Function }
    },
    data() {
        return {
            clientInformation: {
                lastname: { label: 'Nom', type: 'text', key: 'lastname', value: "" },
                firstname: { label: 'Prénom', type: 'text', key: 'firstname', value: "" },
                email: { label: 'Adresse e-mail', type: 'email', key: 'email', value: "" },
                mobile: { label: 'Numéro de téléphone', type: 'tel', key: 'mobile', value: "" },
                birthDate: { label: 'Date de naissance', type: 'date', key: 'birthDate', value: "" },
                adresse: { label: 'Adresse postale', type: 'Adresse', key: 'adresse', value: "" },
                zipcode: { label: 'Code postal', type: 'number', key: 'zipcode', value: "" },
            }
        }
    },
    mounted() {
        if (this.userInformation)
            this.setUserInformation()
    },
    methods: {
        setUserInformation() {
            for (const [key, value] of Object.entries(this.clientInformation)) {
                value.value = this.userInformation[key]
            }
        },
        sendEmail() {
            let that = this
            let data = {}
            data['material'] = this.selectedMaterial.nom
            for (const [key, value] of Object.entries(this.clientInformation)) {
                data[key] = value.value
            }
            data['code'] = ''
            if (this.userInformation && this.userInformation.code)
                data['code'] = this.userInformation.code
            engine.Reservation_Nostock(data, function (data) {
                that.setMaterial(undefined)
                if (data.code == 0) {
                    that.showSnackBarFunct({ color: 'green', text: 'Votre demande sur liste d\'attente a bien été prise en compte.' })
                } else {
                    that.showSnackBarFunct({ color: '#EF5350', text: 'Une erreur est survenue nous vous invitons à recommencer.' })
                }
            })
        }
    }
}
</script>

<style></style>