<template>
  <v-col>
    <v-col style="background-color: var(--C6);">
      <v-col align="left">
        <h3 style="color: var(--C3);font-weight: bold;">
          Sélectionner l'agence de retrait
        </h3>
      </v-col>
      <v-card>
        <v-item-group mandatory v-model="localAgency">
          <v-col>
            <div v-for="(agency, i) in getAgencyFiltered" :key="i" style="width:100%" class="py-1">
              <v-item v-slot="{ isSelected, toggle }">
                <v-card style="border: solid 1px lightgrey;"
                  :style="isSelected ? 'background-color:var(--C1) ;color:white' : ''" class="pa-2" min-height="10"
                  @click="toggle" variant="text">
                  <v-row>
                    <v-col align="left" cols="auto">
                      {{ agency.PR_NOM_AG }}
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col align="right" cols="auto">
                      {{ agency.PR_ADRESSE_AG }} {{ agency.PR_CP_AG }} {{ agency.PR_VILLE_AG }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-item>
            </div>
          </v-col>
        </v-item-group>
      </v-card>
    </v-col>
    <v-col style="background-color: #ECEFF1;" class="mt-6" v-if="selectedAgency">
      <v-col align="left">
        <h3 style="color: var(--C3);font-weight: bold;">
          Sélectionner une date de retrait
        </h3>
      </v-col>
      <v-card class="mt-2">
        <calendar-reservation id="timePicker" :agency="selectedAgency" :list_agencies="getAgencyFiltered"
          @setMeetId="setMeetId($event)"></calendar-reservation>
      </v-card>
    </v-col>
  </v-col>
</template>
  
<script>

import calendarReservation from "@/components/reservation/calendar_reservation.vue"
export default {
  props: {
    selectedMaterial: {},
    agencyListe: {},
    selectedAgency: {},
    setAgency: {
      type: Function
    },
    setMeetId: {
      type: Function
    },
  },
  data() {
    return {
      localAgency: undefined
    }
  },
  components: {
    calendarReservation
  },
  watch: {
    localAgency(val) {
      this.setAgency(this.getAgencyFiltered[val])
      this.scrollToId('timePicker')
    }
  },
  methods: {
    scrollToId(id) {
      setTimeout(function () {
        document.getElementById(id).scrollIntoView({ block: 'center', behavior: 'smooth' })
      }, 100);
    }
  },
  computed: {
    getAgencyFiltered() {
      let agencyFiltered = this.agencyListe.filter((val) => val.MATERIAL_TO_SHOW == undefined || val.MATERIAL_TO_SHOW == '' || val.MATERIAL_TO_SHOW.split(',').includes(this.selectedMaterial.famille) || val.MATERIAL_TO_SHOW.split(',').includes(this.selectedMaterial.designation))
      return agencyFiltered
    }
  }
}
</script>
  
<style></style>