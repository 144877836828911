<template>
    <v-dialog v-model="reservationIsOpen" persistent fullscreen>
        <v-card style="background-color: white; position: sticky;top:0px;bottom:0px">
            <v-col class="pa-0">
                <reservation-view :selectedFamilyToShow="familySelectedTab"></reservation-view>
            </v-col>
        </v-card>
    </v-dialog>
    <div>
        <h2 class="pa-0 pb-4 subTitletext" align="left" style="color: var(--C3);font-weight: bold;">
            Notre sélection de materiels
        </h2>
        <div v-if="familyList" align="center">
            <v-row align=center>
                <v-col v-for="(elem, idx) in familyList" :key="idx" cols="12" sm="12" md="6" lg="6">
                    <v-card @click="openReservation(elem)" class="familyCard" height="100%" variant="flat" style="border: solid 1px #E0E0E0;">
                        <v-col>
                            <v-row justify="center" align="center">
                                <v-col cols="auto">
                                    <img :src="'./family/' + elem.Nom_Famille_Principale + '.jpg'" style="height: 120px;" />
                                    <!-- style="height: 30vw;min-height: 200px; max-height: 300px;" -->
                                </v-col>
                                <v-col cols="auto">
                                    <h2 style="text-transform: lowercase;" class="title">
                                        {{ elem.WEB_NOM_FAMILLE }}
                                    </h2>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
            <!-- <v-row justify="center">
            <v-col v-for="(mat, i) in materialList" :key="i" md="6" cols="12">
                <v-card class="pa-2 cardHome" variant="flat" width="" style="cursor:pointer" @click="goTo('reservation')"
                    height="100%">
                    <div style="background-color: white;">
                        <v-row align="center">
                            <v-col v-if="mat.url" lg="7" cols="12">
                                <img v-if="mat.url" :src="mat.url" style="width: 20vw;min-width: 300px;"
                                    alt="mat.titre1"><img>
                            </v-col>
                            <v-col lg="5" cols="12">
                                <div align=left>
                                    <h2>
                                        {{ mat.titre1 }}
                                    </h2>
                                    <v-divider></v-divider>
                                    <h5 class="pa-1">
                                        {{ mat.titre2 }}
                                    </h5>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
        </v-row> -->
        </div>
        <basic-loading v-else></basic-loading>
    </div>
</template>

<script>
import BasicLoading from '@/components/basic-loading.vue'
import ReservationView from '@/views/ReservationView.vue'

export default {
    data() {
        return {
            reservationIsOpen: false,
            familySelectedTab: []
        }
    },
    props: {
        // materialList: {},
        familyList: {}
    },
    methods: {
        openReservation(family) {
            this.$router.push('reservation?family=' + family.Nom_Famille_Principale)
            // let familySelectedTabTmp = []

            // for (let i = 0; i < family.length; i++) {
            //     familySelectedTabTmp.push(family[i]['Nom_Famille_Principale'])
            // }
            // familySelectedTabTmp.push(family['Nom_Famille_Principale'])
            // this.familySelectedTab = familySelectedTabTmp
            // this.reservationIsOpen = true
        },
        goTo(url) {
            this.$router.push(url)
        }
    },
    mounted() {

    },
    components: {
        BasicLoading,
        ReservationView,
    }
}

</script>
