import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
// import { StripeCheckout } from '@vue-stripe/vue-stripe';

loadFonts()

const app = createApp(App)

app.use(router)
app.use(vuetify)
    // app.use(StripeCheckout)

app.mount('#app')
    // createApp(App).use(router)
    //     .use(vuetify)
    //     .mount('#app')