<template>
	<v-dialog v-if="generatedPayZenKey" v-model="payZenModalIsopen" persistent>
		<PayzenPayment @close="closePayZenModal()" v-if="generatedPayZenKey" :generatedPayZenKey="generatedPayZenKey">
		</PayzenPayment>
	</v-dialog>

	<v-col v-if="selectedMaterial">
		<v-col style="background-color: var(--C6);">
			<v-col align="left" class="mb-3">
				<h3 style="color: var(--C3);font-weight: bold;">
					Récapitulatif de votre réservation
				</h3>
			</v-col>
			<v-card>
				<v-col cols="12" class="px-0 pt-0">
					<div class="px-2" style="background-color: var(--C1); color: white;font-weight: normal;">
						<v-row class="pa-3">
							<v-col align="left" cols="12" md="7" class="pb-0 pl-1">
								<table>
									<tr>
										<td class="pa-1">
											{{ selectedMaterial['label'] }}
										</td>
										<td class="pa-1">
											<span>
												{{ selectedPaymentAmount['amount'].toFixed(2) }}€
											</span>
										</td>
									</tr>
									<tr v-for="(elem, i) in selectedAccessoire" :key="i" align="left">
										<td class="pa-1">
											{{ elem['ComplementLocation::Désignation'] }}
										</td>
										<td class="pa-1" v-if="elem.price">
											<span>
												{{ elem.price.find((val) => val.nom ==
													selectedPaymentType.nom).prices[this.selectedPaymentAmount.type == 1 ? 0 : 1
												].amount.toFixed(2)
												}}€
											</span>
										</td>
									</tr>
								</table>
							</v-col>
							<v-col cols="12" md="5" align="right" style="text-transform: uppercase;">
								<span>
									{{ selectedPaymentAmount['label'] }} :
									{{ getFinalPrice.account }}€
								</span>
							</v-col>
						</v-row>
					</div>
				</v-col>
				<!-- {{ getFinalPrice }} -->
				<v-col align="left" class="pt-1" style="color:grey;">
					<div style="font-size: 12px" class="">
						{{ selectedPaymentAmount['description'] }}
					</div>
					<div v-if="selectedMaterial && selectedMaterial.caution" style="font-size: 12px" class="">
						Une caution de {{ selectedMaterial.caution }} € sera demandée au commencement de votre location.
					</div>
					<div style="color: black;" class="mt-2">
						<v-checkbox v-model="isCgvAccepted" density="compact" hide-details
							label="J'accepte les CGUV et la politique de confidentialité"></v-checkbox>
					</div>
				</v-col>
				<v-col>
					<div>
						<v-btn :disabled="!isCgvAccepted" @click="creat_accen_contrat" variant="flat"
							style="color:white;background-color: var(--C1);">Régler ma réservation</v-btn>
					</div>
				</v-col>
			</v-card>
		</v-col>
	</v-col>
</template>
  
<script>


import engine from "@/core/DataAPI";
import PayzenPayment from "@/components/payment/payzen_payment.vue"
// import { loadStripe } from '@stripe/stripe-js';
// import StripeCheckout from '@/components/stripe/stripe_checkout.vue'
import moment from 'moment'


export default {
	data() {
		return {
			payZenModalIsopen: false,
			generatedPayZenKey: undefined,
			clientSecret: undefined,
			isCgvAccepted: false,
			openStripModale: false,
			stripe: undefined
		}
	},
	components: {
		PayzenPayment
		// StripeCheckout
	},
	computed: {
		getFinalPrice() {
			let price = parseFloat(this.selectedPaymentAmount['amount'].toFixed(2))
			let total = parseFloat(this.selectedPaymentType.prices.find((val) => val.type == 1).amount.toFixed(2))
			if (this.selectedAccessoire)
				for (let i = 0; i < this.selectedAccessoire.length; i++) {
					let add = this.selectedAccessoire[i].price.find((val) => val.nom == this.selectedPaymentType.nom)
					if (add) {
						if (this.selectedPaymentAmount.type == 1) {
							price = price + parseFloat(add.prices[0].amount.toFixed(2))
						} else if (this.selectedPaymentAmount.type == 2) {
							price = price + parseFloat(add.prices[1].amount.toFixed(2))
						}
						total = total + parseFloat(add.prices[0].amount.toFixed(2))
					}
				}
			return { account: parseFloat(price).toFixed(2), total: total.toFixed(2) }
		}
	},
	methods: {
		closePayZenModal() {
			this.payZenModalIsop = false
			this.generatedPayZenKey = undefined
		},
		async set_paiement_token(numero) {
			console.log("set_paiement_token", numero);
			let that = this
			engine.create_payment_intent(numero, this.userInformation.email, parseFloat((this.getFinalPrice.account * 100).toFixed(2)), function (data) {
				console.log("create_payment_intent", data);
				if (data.code == 0) {
					that.generatedPayZenKey = data.data.answer.formToken
				}
			})
		},
		onPaid(event) {
			if (event.clientAnswer.orderStatus === "PAID") {
				// Remove the payment form
				// KR.removeForms();

				// Show success message
				document.getElementById("paymentSuccessful").style.display = "block";
			} else {
				// Show error message to the user
				alert("Payment failed !");
			}
		},
		dataURLtoFile(dataURI) {
			var byteString = atob(dataURI.split(',')[1]);
			var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			var ab = new ArrayBuffer(byteString.length);
			var ia = new Uint8Array(ab);

			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			return new Blob([ab], { type: mimeString });
		},
		creat_accen_contrat() {
			console.log("creat_accen_contrat");
			let that = this
			let resaData = {
				materials: this.selectedMaterial,
				accessories: this.selectedAccessoire,
				agencies: this.selectedAgency,
				pricesType: this.selectedPaymentType,
				// totalPrice: this.getFinalPrice.total,
				totalPrice: this.getFinalPrice.account,
				pricesAmount: this.selectedPaymentAmount,
				user: this.userInformation,
				payment_id: this.clientSecret,
				options: {
					// is_main_bank: this.account.is_main_bank,
					// banq_account: this.banq_account,
					planning_rdv_location: this.selectedAgency['PR_NOM_AG'],
					planning_rdv_key: this.selectedMeetId,
					planning_rdv_date: moment(this.selectedMeetDate).format('DD/MM/YYYY HH:mm'),
				}
			}
			engine.Reservation_Create(resaData, function (data) {
				that.openStripModale = true
				if (data.code == 0) {
					for (let i = 0; i < that.filesToLoad.length; i++) {
						let filedata = that.filesToLoad[i]
						if (filedata.type == 'file' && filedata.value != undefined && filedata.value.length > 0) {
							engine.File_Upload(that.userInformation.code, data.data + '/DOCUMENTS/', filedata.filename, filedata.value, function () {
							})
						}
					}
					console.log('resaData', resaData);
					console.log('data', data.data);
					if (parseFloat(resaData.totalPrice) == 0) {
						engine.reservation_free(data.data, function (dataFree) {
							if (dataFree.code == 0) {
								that.$router.push({ path: '/validation', query: { type: 'succes' } })
							} else {
								that.$router.push({ path: '/validation', query: { type: 'error' } })
							}
						})
					} else {
						that.set_paiement_token(data.data)
						that.payZenModalIsopen = true
					}
				}
			})
		},
	},
	props: {
		filesToLoad: {},
		selectedMaterial: {},
		selectedPaymentAmount: {},
		selectedPaymentType: {},
		selectedAccessoire: {},
		selectedAgency: {},
		selectedMeetId: {},
		userInformation: {},
		selectedMeetDate: {},
		materialList: {}
	},
}
</script>