<template>
    <!-- {{ step }} -->
    <v-col class="pb-1" style="text-align: center;">
        <div
            style="font-size: 17px;width:100%;text-align: center; border: solid 1px #BDBDBD;background-color: white;border-radius: 15px;">
            Récapitulatif
        </div>
    </v-col>
    <v-col class="pa-1">
        <div v-if="step >= 2 && selectedMaterial" >
            <div class="drawerCard" @click="scrollToId('material')">
                <v-row justify="center" align=center>
                    <v-col cols="4">
                        <div align=center class="dIcon">
                            <v-icon>mdi-bike</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="8" align="left">
                        <div class="dtitle">
                            1 - Materiel sélectionné
                        </div>
                        <div class="dSubtitle">
                            {{ selectedMaterial.label }}
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-divider></v-divider>
        </div>
        <div v-if="step >= 3 && selectedAgency">
            <div class="drawerCard" @click="scrollToId('agency')">
                <v-row justify="center" align=center>
                    <v-col cols="4">
                        <div align=center class="dIcon">
                            <v-icon>mdi-google-maps</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="8" align="left">
                        <div class="dtitle">
                            2 - Agence sélectionnée
                        </div>
                        <div class="dSubtitle">
                            {{ selectedAgency.PR_NOM_AG }}
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-divider></v-divider>
        </div>
        <div v-if="step >= 5">
            <div class="drawerCard" @click="scrollToId('payment')">
                <v-row justify="center" align=center>
                    <v-col cols="4">
                        <div align=center class="dIcon">
                            <v-icon>mdi-clipboard-file</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="8" align="left">
                        <div class="dtitle">
                            3 - Abonnement sélectionné
                        </div>
                        <div class="dSubtitle">
                            {{ selectedPaymentType.nom }}
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-divider></v-divider>
            <!-- <div class="drawerCard" @click="scrollToId('payment')">
                <div align=center class="dIcon">
                    <v-icon>mdi-clipboard-file</v-icon>
                </div>
                <div class="dtitle">
                    3 - Abonnement sélectionné
                </div>
                <div class="dSubtitle">
                    {{ selectedPaymentType.nom }}
                </div>
            </div>
            <v-divider></v-divider> -->
        </div>
        <div v-if="step >= 6">
            <div class="drawerCard" @click="scrollToId('subscription')">
                <v-row justify="center" align=center>
                    <v-col cols="4">
                        <div align=center class="dIcon">
                            <v-icon>mdi-currency-eur</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="8" align="left">
                        <div class="dtitle">
                            4 - Paiement sélectionné
                        </div>
                        <div class="dSubtitle">
                            {{ selectedPaymentAmount.label }}
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-divider></v-divider>
            <!-- <div class="drawerCard" @click="scrollToId('subscription')">
                <div align=center class="dIcon">
                    <v-icon>mdi-currency-eur</v-icon>
                </div>
                <div class="dtitle">
                    4 - Paiement sélectionné
                </div>
                <div class="dSubtitle">
                    {{ selectedPaymentAmount.label }}
                </div>
            </div>
            <v-divider></v-divider> -->
        </div>
    </v-col>
</template>

<script>
export default {

    props: {
        scrollToId: {
            type: Function
        },
        step: {},
        selectedMaterial: {},
        selectedAccessoire: {},
        selectedAgency: {},
        selectedPaymentType: {},
        selectedPaymentAmount: {},
    },
}
</script>

<style></style>