<template>
  <div class="planning" v-if="verifClientIsOver && isValidUser">
    <v-dialog v-model="isModalDataListeOpenVerif" v-if="isModalDataListeOpenVerif" width="99%" max-width="300">
      <v-card>
        <v-col align="center">
          <div class="mb-4" style="font-weight:bold;">
            Valider ma suppression ?
          </div>
          <v-row>
            <v-col cols="6" class="pa-1">
              <v-btn @click="removeRdv(modalData.info)" style="width:100%;color:white;background-color: var(--C4);">
                Oui
              </v-btn>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-btn @click="isModalDataListeOpenVerif = false" style="width:100%;color:white;" color="#EF5350">
                Non
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isModalDataListeOpen" v-if="isModalDataListeOpen" width="500" max-width="99%">
      <v-card style="">
        <v-col v-if="(modalData && modalData.info && modalData.info.extendedProps)" align="center" class="pb-1">
          <h3 style="color:var(--C1)">
            {{ modalData.info.title }}
          </h3>
        </v-col>
        <v-col cols="12" class="">
          <v-card class="pa-2" align="center" variant="flat" outlined style=" background-color: var(--C1);
              border: solid 1px lightgrey;
              color:white;
              text-transform: capitalize;
            ">
            <div v-if="modalData && modalData.dates">
              {{ modalData.dates['day'] }} <br>
              Début -
              {{
                modalData.dates['start']
              }}
            </div>
          </v-card>
        </v-col>
        <div v-if="!modalData.isDeletable" style="color:red;font-weight: bold;" align="center" class="px-2 pb-2">
          L'annulation d'un RDV n'est autorisée que dans un délai de plus de 24h avant le RDV
        </div>
        <v-col>
          <v-row justify="center" align="center" class="mb-2 mt-1">
            <v-btn v-if="modalData.isDeletable" @click="isModalDataListeOpenVerif = true" class="mx-2" color="#E57373"
              style="color:white">
              Supprimer le RDV
            </v-btn>
            <v-btn @click="isModalDataListeOpen = false" class="mx-2" color="#5C6BC0" style="color:white">
              Fermer </v-btn>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lastValidModal" v-if="lastValidModal" width="99%" max-width="300">
      <v-card>
        <v-col align="center">
          <div class="mb-4" style="font-weight:bold;">
            Valider mon rendez-vous ?
          </div>
          <v-row>
            <v-col cols="6" class="pa-1">
              <v-btn @click="setNewDispo()" style="width:100%;color:white;background-color: var(--C4);">
                Oui
              </v-btn>
            </v-col>
            <v-col cols="6" class="pa-1">
              <v-btn @click="(lastValidModal = false)" style="width:100%;color:white;" color="#EF5350">
                Non
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isModalDataOpen" v-if="show" width="90%" max-width="600">
      <v-card class="pa-1" style="background-color: var(--C6);">
        <v-col>
          <v-row class="py-1" justify="center" align="center">
            <v-col cols="12" class="py-1" align="center">
              <v-card outlined class="pa-2" style="background-color: var(--C1);color: white;">
                <div style="font-size: 18px; font-weight: bold">
                  Adresse
                </div>
                {{ selectedAgency.PR_NOM_AG }}
                {{ selectedAgency.PR_ADRESSE_AG }}
                {{ selectedAgency.PR_CP_AG }}
                {{ selectedAgency.PR_VILLE_AG }}
              </v-card>
            </v-col>
            <v-col v-for="(elem, idx) in modalForm" :key="idx" cols="12" class="py-1">
              <v-card class="pa-2" align="center" style="
                                text-transform: capitalize;background-color: var(--C1);color:white
                              ">
                <div v-if="elem.key1 == 'dates' &&
                  modalData[elem.key1] &&
                  modalData[elem.key1][elem.key2]
                  ">
                  {{ elem.label }} -
                  {{
                    formatDate(
                      modalData[elem.key1][elem.key2],
                      "DD MMMM YYYY HH:mm"
                    )
                  }}
                </div>
                <div v-if="elem.key1 != 'dates' &&
                    modalData[elem.key1] &&
                    modalData[elem.key1][elem.key2]
                    ">
                  {{ elem.label }} -
                  {{ modalData[elem.key1][elem.key2] }}
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" class="py-1" align="center"
              v-if="(modalData && modalData.info && modalData.info.extendedProps)">
              <v-card outlined class="pa-2" style="background-color: var(--C1);color: white;">
                <div style="font-size: 18px; font-weight: bold">
                  Sélectionner un type de rendez-vous
                </div>
                <v-card class="mt-1" style="background-color: var(--C1);color: white;">
                  <!-- <v-chip v-if="(modalData.info.extendedProps.data.TYPE_ID != 0)" style="color:white"> -->
                  <!-- :color="rdvTypeList.find((val) => val.KEY == modalData.info.extendedProps.data.TYPE_ID).COLOR"                     -->
                  <!-- {{ rdvTypeList.find((val) => val.KEY == modalData.info.extendedProps.data.TYPE_ID).LABEL_CLIENT }} -->
                  <!-- {{  modalData.info.extendedProps.data.TYPE_ID  }}
                  </v-chip> -->
                  <div>
                    <v-select v-if="isRecuperationDeVelo == false" return-object item-title="LABEL_CLIENT"
                      :items="getTypeLabels(modalData.info.extendedProps.data.TYPE_ID)" class="mt-1"
                      v-model="selectedTypeInEvent" variant="solo" label="Spécifier un type de rendez-vous"
                      style="border: none" hide-details>
                      <!-- <template v-slot:selection="{ item }">
                        <div style="width:100%" align="center">
                          <v-chip :color="item.COLOR" style="color:white">
                            {{ item.LABEL_CLIENT }}
                          </v-chip>
                        </div>
                      </template> -->
                    </v-select>
                    <v-card v-else class="mt-1 pa-2">
                      <v-chip color="primary">
                        <span> Récupérer mon vélo </span>
                      </v-chip>
                    </v-card>
                  </div>
                </v-card>
              </v-card>
            </v-col>
            <v-col v-if="selectedTypeInEvent" cols="12" class="pt-1">
              <!-- {{ selectedTypeInEvent }}
              {{ actifContratList }} -->
              <v-card style="background-color: var(--C1);color:white;">
                <v-col class="pa-1" cols="12"
                  v-for="(item, idx) in selectedTypeInEvent.PLANNING_CLIENT_QUESTIONS.split('|')" :key="item">
                  <v-text-field v-if="idx != 0" v-model="infoRdv[item]" :label="item" hide-details>
                  </v-text-field>
                  <div v-else>
                    <v-select v-if="actifContratList && actifContratList.length > 0" :items="actifContratList"
                      item-title="Contrat Loc::N° Contrat Comp" return-object v-model="infoRdv[item]" :label="item"
                      hide-details>
                      <template v-slot:selection="{ item }">
                        <div>
                          {{ item.title }}
                          <span
                            v-if="item.value['Contrat Loc::N° CDE Client'] && item.value['Contrat Loc::N° CDE Client'].length > 0">
                            - {{ item.value['Contrat Loc::N° CDE Client'] }}
                          </span>
                        </div>
                      </template>
                      <template v-slot:item="{ props, item }">
                        <v-list-item v-bind="props" :title="item.title"
                          :subtitle="item.value['Contrat Loc::N° CDE Client']"></v-list-item>
                      </template>
                    </v-select>
                    <v-text-field v-else v-model="infoRdv[item]" append-icon="" :label="item" hide-details></v-text-field>
                  </div>
                </v-col>
              </v-card>
            </v-col>
            <!-- <v-col cols="12" align="center" v-if="selectedTypeInEvent || isRecuperationDeVelo">
              <div class="">
                <div v-if="selectedTypeInEvent && selectedTypeInEvent.KEY && selectedTypeInEvent.KEY == 3">
                  <v-text-field outlined v-model="infoRdv['Kilometre parcouru']"
                    label="* Nombre de kilometre parcouru (obligatoire)">
                  </v-text-field>
                </div>
                <div v-if="selectedTypeInEvent && selectedTypeInEvent.KEY && selectedTypeInEvent.KEY == 4">
                  <v-text-field v-model="infoRdv['MOTIF ou Code erreur']" outlined label="* Motif (obligatoire)">
                  </v-text-field>
                </div>
              </div>
              <div
                v-if="formIsValide(infoRdv) || (selectedTypeInEvent && selectedTypeInEvent.KEY && selectedTypeInEvent.KEY == 2) || isRecuperationDeVelo">
                <div style="font-size: 18px; font-weight: bold">
                  Sélectionner une réservation
                </div>
                <v-autocomplete v-if="isRecuperationDeVelo" :items="noActifContratList" item-text="N° CDE Client"
                  item-title="N° CDE Client" return-object hide-details outlined style="border:none"
                  v-model="selectedActifContrat" no-data-text="Aucune réservation en cours.">
                </v-autocomplete>
                <v-autocomplete v-else :items="actifContratList" item-text="N° CDE Client" item-title="N° CDE Client"
                  return-object hide-details outlined style="border:none" v-model="selectedActifContrat"
                  no-data-text="Aucune réservation en cours.">
                </v-autocomplete>
              </div>
            </v-col> -->
            <v-col align="center"
              v-if="(selectedTypeInEvent && modalData && modalData.info && modalData.info.extendedProps && isFormValid || isRecuperationDeVelo)">
              <v-btn
                v-if="((modalData.info.extendedProps.data.TYPE_ID != 0 || selectedTypeInEvent)) || isRecuperationDeVelo"
                class="mx-1" @click="valideEvent()" style="background-color: var(--C4);color: white;">
                Valider ma séléction
              </v-btn>
              <v-btn @click="isModalDataOpen = false" style="background-color:red;color: white;">
                Fermer
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <div v-if="verifClientIsOver" class="application">
      <div class="" style="">
        <v-col>
          <v-row class="">
            <v-col cols="12" md="5" lg="4" class="pr-1">
              <div style="border-radius: 5px;">
                <div align="center" style="background-color: var(--C1)">
                  <h2 style="color: white">RDV planifié</h2>
                </div>
                <div style="border: solid 1px lightgrey; " align="center" class="pt-1">
                  <div style="color: red;font-weight:bold;font-size:15px" class="">POUR ANNULER UN RDV, CLIQUER DESSUS <br>
                    PUIS VALIDER LA
                    SUPPRESION.</div>
                </div>
                <full-calendar id="calendar" :options="calendarOptions">
                </full-calendar>
              </div>
            </v-col>
            <v-col cols="12" md="7" lg="8" class="pl-1 reservation">
              <div align="center" style="background-color: var(--C1)">
                <h2 style="color: white">Planifier un RDV</h2>
                <v-row class="mx-1 mt-1 mb-2">
                  <v-col cols="12" sm="6" class="pa-1">
                    <div style="color:white" class="mb-3 pa-1">
                      Agence sélectionnée
                      <v-select item-text="PR_NOM_AG" return-object :items="getFilterAgencyList" class="mt-1"
                        variant="solo" v-model="selectedAgency" solo chips label="Sélectionner une agence"
                        style="border: none" hide-details item-title="PR_NOM_AG">
                      </v-select>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" class="pa-1">
                    <div style="color:white" class="pa-1">
                      Type de rendez-vous
                      <v-select v-if="isRecuperationDeVelo == false" item-text="LABEL_CLIENT" return-object
                        :items="getTypeToShowForSelection" multiple class="mt-1" v-model="selectedType" variant="solo"
                        label="Sélectionner un type de rendez-vous" style="border: none" chips hide-details
                        item-title="LABEL_CLIENT">
                      </v-select>
                      <v-card v-else class="mt-1 pa-2">
                        <v-chip color="#66BB6A" style="color:white">
                          <span> Récupérer mon vélo </span>
                        </v-chip>
                      </v-card>
                      <!-- <v-checkbox v-model="selectAllType" hide-details color="white">
                        <template v-slot:label>
                          <div style="color:white">
                            Séléctionner l'ensemble des types de rendez-vous </div>
                        </template>
                      </v-checkbox> -->
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-card variant="flat"
                style="border-radius:0px; background-color: var(--C5);border: solid 1px lightgrey;color:black"
                v-if="show == true">
                <v-col>
                  <div style="position:relative">
                    <div v-if="calendarErrorData.show && isRdvtakened == false"
                      style="position:absolute;width:100%;top:200px;z-index: 3;" align="center">
                      <v-card flat style="z-index:2;background-color: var(--C1);border: solid 1px lightgrey;"
                        variant="flat" width="80%">
                        <div v-if="calendarErrorData.msg" style="background-color:var(--C1);color:white" class="pa-2">
                          {{ calendarErrorData.msg }}
                          <a v-if="calendarErrorData.link && calendarErrorData.link.url"
                            :href="calendarErrorData.link.url" style="color: var(--C1);"> {{ calendarErrorData.link.label
                            }} </a>
                        </div>
                        <v-btn v-if="calendarErrorData.showbtn == true" @click="goToDate(calendarErrorData.newDate)"
                          style="width:100%;color:white" variant="text">
                          {{ calendarErrorData.btnMsg }}
                        </v-btn>
                      </v-card>
                    </div>
                    <!-- <full-calendar ref="fullCalendar2" class="reservation px-2 pb-2"
                      id="calendar" :options="calendarOptions2">
                    </full-calendar> -->
                    <full-calendar v-if="isRdvtakened == false" ref="fullCalendar2" class="reservation px-2 pb-2"
                      id="calendar" :options="calendarOptions2">
                    </full-calendar>
                    <div v-else
                      style="height:69.5vh;border: solid 1px lightgray;padding-top:200px;width:100%;font-weight: bold;"
                      align="center">
                      Vous êtes limitée à un RDV maximum en simultanée !
                    </div>
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>
  </div>
  <v-col v-else style="margin-top:30vh">
    <basic-loading></basic-loading>
  </v-col>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
// import "@fullcalendar/core/vdom";
import engine from "@/core/DataAPI.js";
// import login from "./IconLogin";
import listPlugin from "@fullcalendar/list";
import fr from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import clientHook from '@/hooks/clientHook';
import BasicLoading from '@/components/basic-loading.vue'

export default {
  components: {
    FullCalendar,
    // login,
    BasicLoading
  },
  setup() {
    const {
      userInformation,
      isValidUser,
      verifClientIsOver,
      isUserConnected
    } = clientHook()

    return { isValidUser, isUserConnected, verifClientIsOver, userInformation }
  },
  data() {
    return {
      isModalDataListeOpenVerif: false,
      infoRdv: {},
      calendarErrorData: {},
      modalForm: [
        { key1: "dates", key2: "start", label: "Début" },
        // { key1: "dates", key2: "end", label: "Fin" },
      ], modalData: {},
      noActifContratList: [],
      actifContratList: [],
      // selectedActifContrat: undefined,
      selectAllAgency: true,
      selectAllType: true,
      selectedTypeInEvent: undefined,
      lastValidModal: false,
      isModalDataOpen: false,
      isModalDataListeOpen: false,
      currentCalendarDate: { start: undefined, end: undefined },
      formatedEventsBooked: undefined,
      eventsBooked: undefined,
      show: false,
      agencyList: [],
      rdvTypeList: [],
      selectedAgency: undefined,
      selectedType: [],
      eventList: [],
      // rdvType: ["Restitution d'un vélo", "Réparation d'un vélo"],
      loading: false,
      // vdate: "",
      list: [],
    };
  },
  watch: {
    verifClientIsOver(val) {
      if (val == true) {
        if (this.isValidUser == true) {
          this.setClient()
        } else {
          this.$router.push('/')
        }
      }
    },
    selectedTypeInEvent() {
      this.infoRdv = {}
    },
    eventList(val) {
      let that = this
      if (val.length == 0) {
        let limite = moment().add(2, 'd').set({ hour: 0, minute: 0, second: 0 }).format('x')
        let start = this.currentCalendarDate.start
        let end = this.currentCalendarDate.end

        end = moment(parseInt(end)).set({ hour: 23, minute: 59, second: 59 }).format('x')
        start = moment(parseInt(start)).set({ hour: 0, minute: 0, second: 1 }).format('x')

        if (parseInt(start) < parseInt(limite)) {
          start = limite
        }

        let engineData = []
        let endPlus30 = moment(parseInt(end)).add(30, 'days').set({ hour: 23, minute: 59, second: 59 }).format('x')
        if (this.isRecuperationDeVelo == false) {
          for (let i = 0; i < this.selectedType.length; i++) {
            engineData.push({
              'AGENCE': '==' + this.selectedAgency.PR_NOM_AG,
              'VILLE': '==' + this.selectedAgency.PR_VILLE_AG,
              'TYPE_ID': '==' + this.selectedType[i].KEY,
              'JOUR': start + '...' + endPlus30,
              'SUPPRIMER': '==0',
              'LIBRE': '==0',
            })
          }
        }
        else {
          let fmData = {
            'AGENCE': '==' + this.selectedAgency.PR_NOM_AG,
            'VILLE': '==' + this.selectedAgency.PR_VILLE_AG,
            'TYPE_ID': '==1',
            'JOUR': start + '...' + endPlus30,
            'SUPPRIMER': '==0',
            'LIBRE': '==0',
          }
          engineData.push(fmData)
        }
        engine.get_dispo_between_date_by_types_and_agency(
          engineData, function (data) {
            if (data.code == 0) {
              let tab = data.data.data.sort((a, b) => a.fieldData.JOUR - b.fieldData.JOUR);
              if (moment(tab[0].fieldData.JOUR).isAfter(moment(parseInt(end)))) {
                that.calendarErrorData = {
                  show: true,
                  showbtn: true,
                  btnMsg: 'Prochaine disponibilitée le : ' + moment(tab[0].fieldData.JOUR).format('DD/MM/YYYY'),
                  newDate: moment(tab[0].fieldData.JOUR)
                }
              }
            } else {
              that.calendarErrorData = {
                show: true,
                msg: 'Aucun RDV disponible sur cette commune pour les 30 prochains jours. N\'hésitez pas à choisir un autre lieu de distribution ci-dessus ou à contacter.',
                // link: { label: 'Avaé', url: 'https://www.leveloplus.com/contact' },
                showbtn: false,
                // btnMsg: 'testBtn',
                // newDate: undefined
              }
            }
          })
      }
      // else {
      //   this.calendarErrorData = {}
      // }
    },
    isModalDataOpen(val) {
      if (val == false) {
        this.selectedTypeInEvent = undefined
        this.infoRdv = {}
      }
    },
    selectAllType(val) {
      if (val) {
        this.selectedType = this.getTypeToShowForSelection
      }
    },
    selectedAgency(val) {
      if (val != undefined && val != null && this.selectedType.length > 0) {
        this.handleDateChange()
      } else {
        this.show = false
      }
    },
    selectedType(val) {
      if (this.selectedAgency != undefined && this.selectedAgency != null && val.length > 0) {
        this.handleDateChange()
      } else {
        this.show = false
      }
    },
  },
  created: function () {
    var tmain = this;
    // this.$moment.locale("fr");
    tmain.loading = true;
  },

  computed: {
    isFormValid() {
      for (let i = 0; i < this.selectedTypeInEvent.PLANNING_CLIENT_QUESTIONS.split('|').length; i++) {
        let key = this.selectedTypeInEvent.PLANNING_CLIENT_QUESTIONS.split('|')[i]
        if (key.includes('*') && (this.infoRdv[key] == undefined || this.infoRdv[key].length == 0)) {
          return false
        }
      }
      return true
    },
    isRecuperationDeVelo() {
      if (this.actifContratList.length == 0 && this.noActifContratList.length != 0) {
        return true
      }
      else return false
    },
    isRdvtakened() {
      let today = moment()
      let tabRdvInFuture = []
      console.log("formatedEventsBooked", this.formatedEventsBooked)
      if (this.formatedEventsBooked) {
        tabRdvInFuture = this.formatedEventsBooked.filter((val) => moment(val.start).isAfter(today))
      }
      if (tabRdvInFuture.length >= 1)
        return true
      else return false
    },
    showPrev() {
      if (this.currentCalendarDate.start <= moment().format('x'))
        return false
      return true
    },
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    getFilterAgencyList() {
      return this.agencyList
    },
    getFilterTypeList() {
      if (this.rdvTypeList == undefined) return []
      else {
        let typeFiltered = this.rdvTypeList.filter((val) => val.SHOW_TO_CLIENT_SIDE == 0 && val.KEY != 0)
        return typeFiltered
      }
    },
    getTypeToShowForSelection() {
      if (this.rdvTypeList == undefined) return []
      else {
        let typeFiltered = this.rdvTypeList.filter((val) => val.SHOW_TO_CLIENT_SIDE == 0)
        return typeFiltered
      }
    },
    getPlannedEvent() {
      let events = [];

      events.push({
        title: "Récupération de vélo",
        start: "2022-10-14T10:00:00",
        end: "2022-10-14T11:00:00",
        color: "#5C6BC0",
      });
      events.push({
        title: "Visite d'entretient",
        start: "2022-11-02T15:00:00",
        end: "2022-11-02T16:00:00",
        color: "#66BB6A",
      });
      events.push({
        title: "Restitution d'un vélo",
        start: "2022-11-28T09:00:00",
        end: "2022-11-28T10:00:00",
        color: "#FF8A65",
      });

      return events;
    },
    calendarOptions() {
      let option = {};
      option = {
        timeZone: "UTC",
        height: '79vh',
        selectable: true,
        schedulerLicenseKey: "0359429366-fcs-1650894843",
        allDaySlot: false,
        plugins: [listPlugin],
        initialView: "listAllYears",
        titleFormat: { year: "numeric", month: "short", day: "numeric" },
        locale: fr,
        events: this.formatedEventsBooked,
        eventClick: this.handleEventListClick,
        views: {
          listAllYears: {
            type: 'listYear',
            duration: { years: 10 },
          },
        },
        headerToolbar: {
          left: "",
          center: "",
          right: "",
        },
      };
      return option;
    },
    calendarOptions2() {
      let option = {};
      option = {
        timeZone: "UTC",
        selectable: true,
        schedulerLicenseKey: "0359429366-fcs-1650894843",
        plugins: [timeGridPlugin, interactionPlugin, rrulePlugin],
        datesSet: this.handleDateChange,
        initialView: this.isMobile ? "timeGridWeek3Days" : "timeGridWeek",
        height: "68vh",
        titleFormat: { year: "numeric", month: "short", day: "numeric" },
        locale: fr,
        eventClick: this.handleEventClick,
        events: this.eventList,
        allDayText: '',
        headerToolbar: {
          left: this.showPrev ? "prev" : '',
          center: "title",
          right: "next",
        },
        views: {
          timeGridWeek3Days: {
            type: 'timeGrid',
            duration: { days: 3 },
            buttonText: '3 Jours'
          },
        },
      };
      return option;
    },
  },
  methods: {
    setClient() {
      let tmain = this
      let code = this.userInformation.code
      engine.get_no_actif_contract_by_client(code, function (data) {
        if (data.code == 0) {
          if (data.data.data && data.data.data.length != 0)
            tmain.noActifContratList = data.data.data.map((val) => val.fieldData)
        }
      })
      console.log("code", code);
      engine.get_actif_contract_by_client(code, function (data) {
        console.log("get_actif_contract_by_client", data);
        if (data.code == 0) {
          tmain.actifContratList = data.data.data.map((val) => val.fieldData)
          // tmain.selectedActifContrat = tmain.actifContratList[0]
        }
      })
      engine.get_agency_liste(function (data) {
        if (data.code == 0) {
          if (data.data.data.data && data.data.data.data.length != 0) {
            tmain.agencyList = data.data.data.data.map((val) => val.fieldData)
            tmain.agencyList = tmain.agencyList.filter((val) => val.PR_ID_AG > 0)
            tmain.selectedAgency = tmain.getFilterAgencyList[0]
          }
        }
      })
      tmain.updateCalendar()
      tmain.loading = false;
    },
    formIsValide(form) {
      if (form == undefined)
        return false
      let value = Object.values(form)
      if (value && value.length > 0) {
        if (value[0].replace(/ /g, '').length > 0)
          return true
        return false
      }
      return false

    },
    removeRdv() {
      let resa = {}
      let that = this
      resa['planning_rdv_key'] = this.modalData.info.extendedProps.data['Clé primaire']
      resa['numero'] = ""
      resa['courriel'] = ""
      resa['code_client'] = ""
      resa['nom'] = ""
      resa['prenom'] = ""
      resa['telephone'] = ""
      resa['commentaire'] = ""
      resa['LIBRE'] = "0"

      let defaultType = this.modalData.info.extendedProps.data.DEFAULT_TYPE_ID.split(',')
      for (let i = 1; i < defaultType.length; i++) {
        if (defaultType[i])
          resa['TYPE_ID(' + (i) + ')'] = defaultType[i]
        else
          resa['TYPE_ID(' + (i) + ')'] = ""
      }

      engine.edit_dispo(resa, function () {
        that.isModalDataListeOpen = false
        that.isModalDataListeOpenVerif = false
        that.updateCalendar()
      })
    },
    getTypeLabels(idListe) {
      let labelList = []
      if (idListe && this.rdvTypeList.length != 0) {
        for (let i = 0; i < idListe.length; i++) {
          let item = this.rdvTypeList.find((val) => val.KEY == idListe[i] && val.SHOW_TO_CLIENT_SIDE == 0)
          if (item)
            labelList.push(item)
        }
      }
      return labelList
    },
    goToDate(date) {
      date = moment(date).format('YYYY-MM-DD')
      if (date != undefined && this.$refs.fullCalendar2) {
        let calendarApi = this.$refs.fullCalendar2.getApi()
        calendarApi.gotoDate(date);
      }
    },
    updateCalendar() {
      let tmain = this
      // tmain.handleDateChange()
      tmain.formatedEventsBooked = []
      engine.get_rdv_type_liste(function (data) {
        if (data.code == 0) {
          tmain.rdvTypeList = data.data.data.data.map((val) => val.fieldData)
          tmain.selectedType = tmain.getTypeToShowForSelection
        }
        engine.get_dispo_by_code_client(tmain.userInformation.code, function (data) {
          console.log(0);
          if (data.code == 0) {
            console.log(1);
            tmain.eventsBooked = data.data.data.map((val) => val.fieldData)
            console.log("eventsBooked", tmain.eventsBooked);
            let events = []
            for (let i = 0; i < tmain.eventsBooked.length; i++) {
              let event = tmain.eventsBooked[i]
              let eventDay = moment(event.JOUR).format('YYYY-MM-DD')
              let type = []
              let maxTypeNbr = Object.keys(event).filter((val) => val.includes('TYPE_ID(')).length
              for (let index = 1; index <= maxTypeNbr; index++) {
                if (event['TYPE_ID(' + index + ')'] != '')
                  type.push(event['TYPE_ID(' + index + ')'])
              }
              let title = ""
              for (let i = 0; i < type.length; i++) {
                title += tmain.rdvTypeList.find((val) => val.KEY == type[i]).LABEL_CLIENT + " - "
              }
              if (event.RESERVATION_NUM)
                title += event.RESERVATION_NUM + ' - '
              title += event.AGENCE
              events.push({
                title: title,
                textColor: 'black',
                color: tmain.rdvTypeList.find((val) => val.KEY == type[0]).COLOR,
                start: eventDay + 'T' + event.DEBUT + ':00',
                end: eventDay + 'T' + event.FIN + ':00',
                data: event,
              })
            }
            tmain.formatedEventsBooked = events
          }
        })
      })
    },
    formatDate(date, format) {
      return moment(date).lang("fr").format(format);
    },
    handleEventClick(event) {
      let info = event.event._def;
      let dates = info.extendedProps.dates
      this.modalData = {
        info,
        dates,
      };
      this.isModalDataOpen = true;
    },
    handleEventListClick(event) {
      let info = event.event._def;
      let dates = { start: info.extendedProps.data.DEBUT, end: info.extendedProps.data.FIN, day: moment(info.extendedProps.data.JOUR).format('dddd DD MMMM YYYY') };
      this.modalData = {
        info,
        dates,
        isDeletable: moment(info.extendedProps.data.JOUR).isAfter(moment().add(1, 'days').set({ hour: 23, minute: 59, second: 59 }))
      };
      this.isModalDataListeOpen = true
    },
    setNewDispo() {
      console.log("setNewDispo");
      let resa = {}
      let that = this
      resa['planning_rdv_key'] = this.modalData.info.extendedProps.data['Clé primaire']
      // resa['numero'] = this.selectedActifContrat['Contrat Loc::N° CDE Client']

      resa['courriel'] = this.userInformation.email
      resa['code_client'] = this.userInformation.code
      resa['nom'] = this.userInformation.lastname
      resa['prenom'] = this.userInformation.firstname
      resa['telephone'] = this.userInformation.mobile + ' / ' + this.userInformation.phone

      resa['VILLE'] = this.selectedAgency.PR_VILLE_AG
      resa['AGENCE'] = this.selectedAgency.PR_NOM_AG
      resa['ADRESSE'] = this.selectedAgency.PR_ADRESSE_AG
      resa['AGENCE_MAP_LINK'] = this.selectedAgency.GPS
      resa['AGENCE_INDICATIONS'] = this.selectedAgency.INDICATIONS

      let info = ""
      for (const [key, value] of Object.entries(this.infoRdv)) {
        if ((key == 'Numéro de réservation*' || key == 'Numéro de réservation') && typeof value == 'object') {
          info += key + ' : ' + value['Contrat Loc::N° CDE Client'] + " / "
          info += 'Matricule : ' + value['N° Mat interne'] + " / "

          resa['MATRICULE'] = value['N° Mat interne']
          resa['numero'] = value['Contrat Loc::N° CDE Client']
        } else
          info += key + ' : ' + value + " / "
      }
      console.log("info", info);
      console.log("resa", resa);
      resa['commentaire'] = JSON.stringify([{ "user": "CLIENT", "msg": info, "date": moment().format('DD/MM/YYYY HH:mm') }])

      let maxTypeNbr = Object.keys(this.modalData.info.extendedProps.data).filter((val) => val.includes('TYPE_ID(')).length
      for (let index = 1; index < maxTypeNbr; index++) {
        resa['TYPE_ID(' + index + ')'] = ""
      }
      if (this.selectedTypeInEvent)
        resa['TYPE_ID(1)'] = this.selectedTypeInEvent.KEY

      if (this.isRecuperationDeVelo) {
        resa['TYPE_ID(1)'] = '1'
        resa['commentaire'] = ''
      }

      engine.edit_dispo(resa, function () {
        that.isModalDataOpen = false
        that.lastValidModal = false
        that.updateCalendar()
      })
      this.lastValidModal = false
    },
    valideEvent() {
      this.lastValidModal = true
    },
    handleDateChange(date) {
      this.show = true
      let that = this
      let startTimestanp = undefined
      let endTimestanp = undefined
      if (date) {
        startTimestanp = moment(date.startStr).set({ hour: 0, minute: 0, second: 0 }).format('x')
        endTimestanp = moment(date.endStr).subtract(1, 'd').set({ hour: 23, minute: 59, second: 59 }).format('x')
        // let endTimestanp = moment(date.endStr).subtract(1, 'd').set({ hour: 23, minute: 59, second: 59 }).format('x')
      }
      if ((this.currentCalendarDate.start != startTimestanp && this.currentCalendarDate.end != endTimestanp) || date == undefined) {
        if (date != undefined) {
          this.currentCalendarDate.start = startTimestanp
          this.currentCalendarDate.end = endTimestanp
        }
        // that.eventList = []
        let engineData = []
        // for (let a = 0; a < this.selectedAgency.length; a++) {
        let limite = moment().add(2, 'd').set({ hour: 0, minute: 0, second: 0 }).format('x')
        let fmDateStart = undefined

        if (parseInt(this.currentCalendarDate.start) < parseInt(limite)) {
          fmDateStart = limite
        } else {
          fmDateStart = this.currentCalendarDate.start
        }
        if (this.isRecuperationDeVelo == false) {
          for (let i = 0; i < this.selectedType.length; i++) {
            let fmData = {
              'AGENCE': '==' + this.selectedAgency.PR_NOM_AG,
              'VILLE': '==' + this.selectedAgency.PR_VILLE_AG,
              'TYPE_ID': '==' + this.selectedType[i].KEY,
              'JOUR': fmDateStart + '...' + this.currentCalendarDate.end,
              'SUPPRIMER': '==0',
              'LIBRE': '==0',
            }
            if (fmDateStart && this.currentCalendarDate.end)
              engineData.push(fmData)
          }
        } else {
          let fmData = {
            'AGENCE': '==' + this.selectedAgency.PR_NOM_AG,
            'VILLE': '==' + this.selectedAgency.PR_VILLE_AG,
            'TYPE_ID': '==1',
            'JOUR': fmDateStart + '...' + this.currentCalendarDate.end,
            'SUPPRIMER': '==0',
            'LIBRE': '==0',
          }
          if (fmDateStart && this.currentCalendarDate.end)
            engineData.push(fmData)
        }
        // }
        engine.get_dispo_between_date_by_types_and_agency(engineData, function (data) {
          if (data.code == 0) {
            that.calendarErrorData = {}
            let eventsData = data.data.data.map((val) => val.fieldData)
            let events = []
            for (let i = 0; i < eventsData.length; i++) {
              let event = eventsData[i]
              let maxTypeNbr = Object.keys(event).filter((val) => val.includes('TYPE_ID(')).length
              let type = []
              for (let index = 1; index <= maxTypeNbr; index++) {
                if (event['TYPE_ID(' + index + ')'] != '')
                  type.push(event['TYPE_ID(' + index + ')'])
              }
              event.TYPE_ID = type

              let eventDay = moment(event.JOUR).format('YYYY-MM-DD')
              let title = event.DEBUT

              let eventData = {
                title: title,
                start: eventDay + 'T' + event.DEBUT + '00',
                end: eventDay + 'T' + event.FIN + '00',
                dates: {
                  start: eventDay + 'T' + event.DEBUT + ':00',
                  end: eventDay + 'T' + event.FIN + ':00',
                },
                textColor: 'black',
                data: event,
                allDay: true,
                // color: that.rdvTypeList.find((val) => val.KEY == event.TYPE_ID).COLOR,
                color: 'var(--C3)',
              }
              // if (that.rdvTypeList.find((val) => val.KEY == event.TYPE_ID) && that.rdvTypeList.find((val) => val.KEY == event.TYPE_ID).COLOR)
              //   eventData['color'] = that.rdvTypeList.find((val) => val.KEY == event.TYPE_ID).COLOR
              events.push(eventData)
            }
            that.eventList = events
          } else {
            that.eventList = []
          }
        })
      }
    },
    navigation: function (app) {
      var tmain = this;
      tmain.$router.push("/" + app);
    },
  },
};
</script>
<style></style>
