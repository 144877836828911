<template>
  <v-col>
    <v-col style="background-color: var(--C6);">
      <v-col align="left" class="mb-3">
        <h3 style="color: var(--C3);font-weight: bold;">
          Sélectionner le type d'abonnement
        </h3>
      </v-col>
      <v-card>
        <v-item-group mandatory v-model="selectedPayment">
          <v-col>
            <div v-for="(subscription, i) in selectedMaterial.subscriptions" :key="i" style="width:100%" class="py-1">
              <v-item v-slot="{ isSelected, toggle }">
                <v-card style="border: solid 1px lightgrey;"
                  :style="isSelected ? 'background-color:var(--C1);color:white' : ''" class="pa-2" min-height="10"
                  @click="toggle" variant="text">
                  <v-row>
                    <v-col cols="" md="" align="left" style="text-transform: uppercase;font-weight:bold;">
                      {{ subscription.nom }}
                    </v-col>
                    <v-col cols="" md="" align="right">
                      {{ subscription.libelle }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-item>
            </div>
          </v-col>
        </v-item-group>
      </v-card>
    </v-col>
  </v-col>
</template>
  
<script>
export default {
  data() {
    return {
      selectedPayment: undefined
    }
  },
  watch: {
    selectedPayment(val) {
      this.setSelectedPaymentType(val)
    }
  },
  props: {
    selectedMaterial: {},
    selectedPaymentType: {},
    setSelectedPaymentType: {
      type: Function
    }
  },
  setup() {

  },
}
</script>