<template>
    <home-header :login="login" :verifClientIsOver="verifClientIsOver" :headerTitle="headerTitle"
        :headerSecondeTitle="headerSecondeTitle" :userInformation="userInformation" :isValidUser="isValidUser"
        :isUserConnected="isUserConnected">
    </home-header>
    <div class="main-vue">
        <v-col v-if="isValidUser && verifClientIsOver">
            <pdf-tabs :userInformation="userInformation" :factureToPayListe="factureToPayListe" :updateFacture="updateFacture"></pdf-tabs>
        </v-col>
        <v-col v-else style="margin-top: 30vh;">
            <basic-loading></basic-loading>
        </v-col>
    </div>
</template>

<script>

import HomeHeader from '@/components/header/header-general.vue'
import PdfTabs from '@/components/documents/pdf-tabs.vue'

import BasicLoading from '@/components/basic-loading.vue'
import clientHook from "@/hooks/clientHook"
import engine from "../core/DataAPI"

export default {
    data() {
        return {
            factureToPayListe: {},
            headerTitle: "Documents",
            headerSecondeTitle: "Historique des factures et contrats de locations",
        }
    },
    setup() {
        const {
            login,
            userInformation,
            isValidUser,
            verifClientIsOver,
            isUserConnected
        } = clientHook()

        return {
            login,
            userInformation,
            isValidUser,
            verifClientIsOver,
            isUserConnected
        }
    },
    watch: {
        isValidUser(val) {
            if (val == false) {
                this.$router.push('/')
            } else {
                this.updateFacture()
            }
        }
    },
    methods: {
        updateFacture() {
            console.log("updateFacture");
            let that = this
            that.factureToPayListe = {}
            engine.get_facture(this.userInformation.code, function (data) {
                if (data.code == 0) {
                    for (let i = 0; i < data.data.data.data.length; i++) {
                        let fact = data.data.data.data[i].fieldData
                        that.factureToPayListe[fact.NUMERO] = fact
                    }
                }
            })
        }
    },
    components: {
        HomeHeader,
        BasicLoading,
        PdfTabs
    },
}
</script>

<style></style>