<template>
  <general-header  :login="login" :verifClientIsOver="false" :headerTitle="headerTitle"
    :headerSecondeTitle="headerSecondeTitle"></general-header>
  <v-row class="main-vue" justify="center" align="center" style="height:80vh">
    <v-col cols="11" md="6" v-if="viewdiv == 'reset'">
      <v-card class="pa-2" style="background-color: var(--C6);">
        <v-col>
          <h1 style="margin:0px;margin-top:5px;margin-bottom:7px;color: var(--C1);">Réinitialisation du mot de passe</h1>
          <h3 style="margin:0px;cursor:pointer;color: var(--C3);">Veuillez saisir votre nouveau mot de passe<br />pour
            vous connecter à
            votre
            compte</h3>
        </v-col>
        <v-col align=center>
          <v-text-field v-model="pwd" type="password" label="Votre nouveau mot de passe" hide-details>
          </v-text-field>
          <label v-if="msgpwd && msgpwd.length > 0" style="color:red;font-size:14px;">
            {{ msgpwd }}</label>
        </v-col>
        <v-col align=center>
          <v-text-field v-model="pwdconfirm" hide-details type="password" label="Confirmer votre nouveau mot de passe">
          </v-text-field>
          <label v-if="msgpwdconfirm != ''" style="color:red;font-size:14px;">{{ msgpwdconfirm }}</label>
        </v-col>
        <v-col class="pt-0">
          <label class="divloginpwd">
            Le mot de passe doit contenir minumim 8 caractères, 1 majuscule, 1 minuscule, 1 nombre, 1 caractère spécial
            [@$!%*?&]
          </label>
        </v-col>
        <v-col>
          <v-btn @click="btloginrecovery_valid" style="background-color: var(--C1);color: white;">
            Valider
          </v-btn>
        </v-col>
      </v-card>
    </v-col>
    <v-col v-else cols="11" md="6">
      <v-card class="pa-2" >
        <v-icon size="120" style="color: var(--C1);">
          mdi-emoticon-happy-outline
        </v-icon>
        <h3 style="color: var(--C3);">
          Mot de passe réinitialisé avec succes !
        </h3>
        <v-col>
          <v-row justify="center">
            <v-col cols="auto">
              <a href="/">Accueil</a>
            </v-col>
            <!-- <v-col cols="auto">
              /
            </v-col>
            <v-col cols="auto">
              <a href="/reservation">Reservation</a>
            </v-col> -->
          </v-row>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>
  
<script>
import engine from "../core/DataAPI";
import GeneralHeader from '@/components/header/header-general.vue'
import clientHook from "@/hooks/clientHook"
// import BasicLoading from '@/components/basic-loading.vue'

export default {
  data() {
    return {
      apikey: '',
      loading: false,
      viewdiv: 'done',
      email: '',
      pwd: '',
      pwdconfirm: '',
      msgpwd: '',
      msgpwdconfirm: ''
    }
  },
  setup() {
    const { login, userInformation, isValidUser, verifClientIsOver, isUserConnected } = clientHook()
    return { login, userInformation, isValidUser, verifClientIsOver, isUserConnected }
  },
  components: {
    GeneralHeader,
    // BasicLoading
  },
  created: function () {
    var tmain = this;
    if (JSON.stringify(tmain.$route.query) != '{}') {
      switch (tmain.$route.query.state) {
        case 'recovery':
          tmain.pwd = '';
          tmain.pwdconfirm = '';
          tmain.msgpwd = '';
          tmain.msgpwdconfirm = '';
          tmain.apikey = tmain.$route.query.apikey;
          tmain.viewdiv = 'reset';
          break;
      }
    }
  },
  methods: {
    btloginrecovery_valid: function () {
      var tmain = this;
      var has_error = false;
      tmain.loading = true;
      tmain.msgpwd = "";
      tmain.msgpwdconfirm = "";
      var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@.,;#$%&*()]).{8,}/;
      if (!regExp.test(tmain.pwd)) {
        tmain.msgpwd = "Veuillez respecter les règles de saisie pour le mot de passe."; has_error = true;
      }
      if (tmain.pwd != tmain.pwdconfirm) { tmain.msgpwdconfirm = "Les mots de passe sont différents"; has_error = true; }

      if (!has_error) {
        engine.Account_Recovery_Valid(tmain.apikey, tmain.pwd, function (pkg) {
          tmain.loading = false;
          if (pkg.code == 0) {
            tmain.viewdiv = 'done';
          } else {
            alert("Ce lien n'est plus valide.");
          }
        });
      } else {
        tmain.loading = false;
      }
    },
  }
}
</script>
<style></style>