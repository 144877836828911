<template>
  <v-dialog v-model="isInformationInEditionMode" width="90%" max-width="800">
    <v-card style="background-color: var(--C6);">
      <v-col class="pb-0 pl-0">
        <v-card-title align="left" class="mb-0 pb-0">
          <div style="color: var(--C3);font-weight: bold;">
            Information client modifiable
          </div>
        </v-card-title>
      </v-col>
      <v-col v-for="(value, key) in getUserInformationToEdit" :key="key" class="pb-0">
        <v-card variant="text" style="border:solid 1px lightgray;background-color: white;">
          <v-row justify="center" align="center">
            <v-col cols="2" align="left">
              <div style="padding:10px;">
                {{ keyTranslator[key] }}
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col align="left">
              <div class="pa-1" style=" text-transform: capitalize;">
                <div v-if="isInformationInEditionMode && editableInformation.includes(key)">
                  <v-text-field :type="key == 'birthday' ? 'date' : 'text'" class="mr-2" v-model="tmpEdition[key]"
                    hide-details variant="text" density="compact" style="background-color: #E0E0E0;border-radius: 3px;">
                  </v-text-field>
                </div>
                <div v-else>
                  <div v-if="key == 'birthday'">
                    {{ momentFormat(value, 'DD/MM/YYYY') }}
                  </div>
                  <div v-else>
                    {{ value }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-if="isInformationInEditionMode" align="center" class="mt-2 mb-2">
        <v-btn class="mr-3" style="background-color: var(--C1);color: white;" @click="updateClient">
          Enregistrer les modifications
        </v-btn>
        <v-btn class="ml-3" @click="cancelEdition" style="background-color: var(--C2);color: white;">
          Fermer </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
  <v-col>
    <div v-if="verifClientIsOver && isValidUser">
      <v-col>
        <v-card variant="text" style="background-color: var(--C6);" class="pb-2">
          <v-row justify="center" align=center>
            <v-col class="pl-4">
              <v-card-title align="left" class="mb-0 pb-0">
                <div style="color: var(--C3);font-weight: bold;">
                  Information client
                </div>
              </v-card-title>
            </v-col>
            <v-col align="right">
              <div class="pa-3">
                <v-btn v-if="!isInformationInEditionMode" variant="text"
                  :style="{ 'font-size': isMobile ? '10px' : '15px' }"
                  style="border-radius: 5px;background-color: var(--C1);color:white"
                  @click="isInformationInEditionMode = true"> modifier mes informations personnelles
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-col v-for="(value, key) in getUserInformation" :key="key" class="pt-1 pb-0">
            <v-card variant="text" style="border:solid 1px lightgray;background-color: white;">
              <!-- <v-row justify="center" align="center">
                <v-col cols="" align="left">
                  <div style="padding:10px;">
                    {{ keyTranslator[key] }}
                  </div>
                </v-col> -->
              <!-- <v-divider vertical></v-divider> -->
              <!-- <v-col cols="auto" align="left">
                  <div class="pa-1" style=" text-transform: capitalize;"> -->
              <!-- <div v-if="isInformationInEditionMode && editableInformation.includes(key)">
                      <v-text-field :type="key == 'birthday' ? 'date' : 'text'" class="mr-2" v-model="tmpEdition[key]"
                        hide-details variant="text" density="compact"
                        style="background-color: #ECEFF1;border-radius: 5px;">
                      </v-text-field>
                    </div> -->
              <tbody>
                <tr>
                  <td class="pa-2" align="left">{{ keyTranslator[key] }}: </td>
                  <td class="pa-2">
                    <div v-if="key == 'birthday'">
                      {{ momentFormat(value, 'DD/MM/YYYY') }}
                    </div>
                    <div v-else>
                      {{ value }}
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <div>
                    </div>
                  </div>
                </v-col>
              </v-row> -->
            </v-card>
          </v-col>
        </v-card>
      </v-col>
      <files-verifications :filesToLoad="filesToLoad" :isValidUser="isValidUser"
        :validAllMandatoryFiles="validAllMandatoryFiles"></files-verifications>
      <v-col>
        <v-card variant="flat" style="background-color: var(--C6);">
          <v-card-title align="left">
            <div style="color: var(--C3);font-weight: bold;">
              Actions
            </div>
          </v-card-title>
          <v-col v-for="(elem, i) in eraseUserData" :key="i" class="pb-2 pt-0">
            <v-card variant="text" style="border:solid 1px lightgray;background-color: white;" @click="function () { }">
              <tbody>
                <tr>
                  <td class="pa-2" align="left">{{ elem.label }}: </td>
                  <td class="pa-2" align="left">
                    {{ elem.desc
                    }}
                  </td>
                </tr>
              </tbody>
              <!-- <v-row justify="center" align="center">
                <v-col cols="2" align="left">
                  <div style="padding:10px; text-transform: uppercase;">
                    {{ elem.label }}
                  </div>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col align="left">
                  <div class="pa-1">
                    {{ elem.desc }}
                  </div>
                </v-col>
              </v-row> -->
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </div>
    <basic-loading style="margin-top:30vh" v-else></basic-loading>
  </v-col>
</template>

<script>

import reservationHook from '@/hooks/reservationHook'
import FilesVerifications from '@/components/reservation/files_verifications'
import moment from 'moment'
import BasicLoading from '@/components/basic-loading.vue'
import engine from '@/core/DataAPI';

export default {
  setup() {

    const {
      filesToLoad
    } = reservationHook()


    return { filesToLoad }
  },
  props: {
    userInformation: {},
    isValidUser: {},
    verifClientIsOver: {},
    isUserConnected: {type:Function},
    updateUserInfo: {type:Function}
  },
  data() {
    return {
      keyTranslator: {
        "code": "Code client",
        "address": 'Adresse',
        "city": 'Ville',
        "country": 'Pays',
        "mobile": 'Téléphone',
        "zipcode": 'Code postal',
        "fullname": 'Nom complet',
        "birthday": 'Date de naissance',

      },
      editableInformation: [
        "address",
        // "address2",
        "city",
        "country",
        "mobile",
        // "phone",
        // "zipcode"
      ],
      informationtoShow: [
        "fullname", "address", "birthday", "city", "code", "country", "mobile",
      ],
      isInformationInEditionMode: false,
      tmpEdition: {},
      eraseUserData: [
        { label: "Désactiver", desc: 'Désactiver mon compte en ligne' },
        { label: "Supprimer", desc: 'Supprimer mes données personnelles conformément à la RGPD' },
      ]
    }
  },
  components: {
    FilesVerifications,
    BasicLoading
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown
    },

    getUserInformationToEdit() {
      let filteredInformation = {}
      for (const [key, value] of Object.entries(this.userInformation))
        if (this.editableInformation.includes(key))
          filteredInformation[key] = value

      return filteredInformation
    },

    getUserInformation() {
      let filteredInformation = {}
      for (const [key, value] of Object.entries(this.userInformation))
        if (this.informationtoShow.includes(key))
          filteredInformation[key] = value

      return filteredInformation
    }
  },
  watch: {
    verifClientIsOver(val) {
      if (val == true) {
        if (this.isValidUser != true) {
          this.$router.push('/')
        }
      }
    },
    isInformationInEditionMode(val) {
      if (val == true) {
        this.tmpEdition = JSON.parse(JSON.stringify(this.userInformation))
      } else {
        this.tmpEdition = {}
      }
    }
  },
  methods: {
    updateClient() {
      console.log("updateClient");
      let that = this
      let accenKeysTranslate = {
        address: 'Adresse',
        city: 'Ville',
        country: 'Pays',
        mobile: 'tel_portable',
      }
      let keyToEdit = {}

      for (const [key, value] of Object.entries(accenKeysTranslate)) {
        keyToEdit[value] = this.tmpEdition[key]
      }

      engine.Account_Edit(this.tmpEdition.code, keyToEdit, function (data) {
        if (data.code == 0) {
          that.updateUserInfo()
          that.cancelEdition()
        }
      })
    },
    cancelEdition() {
      this.tmpEdition = {}
      this.isInformationInEditionMode = false
    },
    momentFormat(date, formatType) {
      return moment(date).format(formatType)
    },
  }
}
</script>

<style></style>