<template>
    <v-col align="center" cols="12">
        <v-progress-circular :size="150" :width="3" style="color: var(--C1);" indeterminate></v-progress-circular>
    </v-col>
</template>

<script>
export default {

}
</script>

<style></style>